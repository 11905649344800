import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Row, Col, Select, Typography } from "antd";
import axios from "axios";
import { navigate } from "gatsby";
import { MerchantReferrals } from "../../data/common-data";

import chevron from "../../images/svg/chevron_left.svg";
import { MERCHANT_REFERRAL, submitForm } from "../../utils/firebase/forms";

const { Option } = Select;
const { Title } = Typography;

const MerchantReferralForm = (props) => {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [referrerName, setReferrerName] = useState("");
  const [referrerCompany, setReferrerCompany] = useState("");
  const [referrerEmail, setReferrerEmail] = useState("");
  const [referrerMerchantId, setReferrerMerchantId] = useState("");

  const phoneValidation = new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  );
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const storage = sessionStorage;
      setReferrerName(storage.getItem("referrerName"));
      setReferrerCompany(storage.getItem("referrerCompany"));
      setReferrerEmail(storage.getItem("referrerEmail"));
      setReferrerMerchantId(storage.getItem("referrerMerchantId"));
    }
  });

  const onFinish = async (values) => {
    setLoading(true);

    const response = await axios.get("/api/handler", {
      params: { email: values.email?.trim().toLowerCase() },
    });

    const emailStatus = response?.data?.result?.response?.result;

    if (emailStatus !== "valid" && emailStatus !== "catchall") {
      form.setFields([
        {
          name: "email",
          errors: ["Please enter a valid email"],
        },
      ]);
      setLoading(false);
      return;
    }

    const formData = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email || "",
      phone: values.phone,
      company: values.company,
      referral_product_need: values.product,
      referred_first_name: referrerName || "not available",
      referred_business_name: referrerCompany || "not available",
      referred_email: referrerEmail || "not available",
      referred_hub_link: `https://hub.spoton.com/merchants/${referrerMerchantId}`,
    };

    const result = await submitForm(formData, MERCHANT_REFERRAL);

    if (result) {
      setLoading(false);
      if (window.sessionStorage.getItem("Name")) {
        window.sessionStorage.removeItem("Name", values.firstname);
        window.sessionStorage.setItem("Name", values.firstname);
      } else {
        window.sessionStorage.setItem("Name", values.firstname);
      }
      if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
        // eslint-disable-next-line no-undef
        dataLayer.push({ event: `merchant-referral-lead` });
      }

      navigate("/referral-thank-you");
    } else {
      setLoading(false);
    }

    // axios({
    //   method: "post",
    //   url: `https://api.hsforms.com/submissions/v3/integration/submit/2970821/${props.formId}`,
    //   headers: { "Content-Type": "application/json" },
    //   data: JSON.stringify(formData),
    // })
    //   .then(() => {
    //     if (window.sessionStorage.getItem("Name")) {
    //       window.sessionStorage.removeItem("Name", values.firstname);
    //       window.sessionStorage.setItem("Name", values.firstname);
    //     } else {
    //       window.sessionStorage.setItem("Name", values.firstname);
    //     }
    //     if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
    //       // eslint-disable-next-line no-undef
    //       dataLayer.push({ event: `merchant-referral-lead` });
    //     }
    //   })
    //   .then(() => {
    //     window.location.pathname = "referral-thank-you";
    //   })
    //   // eslint-disable-next-line consistent-return
    //   .catch((error) => {
    //     if (error.response) {
    //       if (
    //         error.response.data.errors[0].errorType.toString() ===
    //         "INVALID_EMAIL"
    //       ) {
    //         // eslint-disable-next-line no-alert
    //         return alert(
    //           `Looks like the email ${values.email} can not be validated.
    //             Please use a valid email.`
    //         );
    //       }
    //     } else if (error.request) {
    //       // eslint-disable-next-line no-console
    //       console.log(error.request);
    //     } else {
    //       // eslint-disable-next-line no-console
    //       console.log("Error", error.message);
    //     }
    //   });
  };
  return (
    <section className="lead-form-wrapper" ref={formRef}>
      <Row className="header_wrapper">
        <Col xs={24}>
          <Title level={1}>
            SpotOn referral program<span className="blue_dot">.</span>
          </Title>
        </Col>
        <Col xs={24}>
          <p>Refer a business that might be interested in a SpotOn Product</p>
        </Col>
      </Row>

      <Form
        name="Merchant Referral"
        className="lead-form"
        layout="vertical"
        form={form}
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        {/* Name */}

        <Form.Item
          label="Referral’s First Name"
          name="firstname"
          rules={[
            {
              required: true,
              message: "Please enter referall's first name",
              min: 2,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. John" />
        </Form.Item>
        <Form.Item
          label="Referral's Last Name"
          name="lastname"
          rules={[
            {
              required: true,
              message: "Please enter referall's last name",
              min: 2,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. Doe" />
        </Form.Item>

        {/* Email */}

        <Form.Item
          label="Referral’s Email Address"
          name="email"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. john_doe@gmail.com" />
        </Form.Item>

        {/* Business Name */}
        <Form.Item
          label="Business name"
          name="company"
          rules={[
            {
              required: true,
              message: "Please enter your referall's business name",
              min: 3,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. Business Name LLC " />
        </Form.Item>

        {/* Phone */}

        <Form.Item
          label="Referral's Phone Number"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please enter your referral's phone",
              pattern: phoneValidation,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. (555) 555-1243 " />
        </Form.Item>

        {/* City/Zip */}

        {/* <Form.Item
          label="Zip code"
          name="zip"
          rules={[
            {
              required: true,
              message: 'Please enter your zip code',
              min: 3,
            },
          ]}
        >
          <Input size="large" placeholder="e.g. 90004" />
        </Form.Item> */}

        {/* Business type */}
        <Col>
          <p className="label-business-type">
            Referral&apos;s Product/Service needed
          </p>
          <Form.Item
            style={{ overflow: "auto" }}
            name="product"
            rules={[
              {
                required: true,
                message: "Please select a product",
              },
            ]}
          >
            <Select
              placeholder="Please select a product"
              style={{ width: "100%", color: "black !important" }}
            >
              {MerchantReferrals.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/* Submit */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="cta-primary"
            style={{ marginTop: 20 }}
            loading={loading}
            size="small"
          >
            Submit request
            <img
              src={chevron}
              alt="chevron icon"
              style={{ margin: "5px 5px 2px 10px" }}
            />
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

MerchantReferralForm.propTypes = {
  formId: PropTypes.string.isRequired,
};

export default MerchantReferralForm;
