import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import MerchantReferralForm from "../components/ConversionPath/MerchantReferralForm";
import Image from "../components/image";
import TrustedNumnbers from "../components/TrustedNumbers/TrustedNumbers";
import PartnerLogos from "../components/PartnerLogos/PartnerLogos";
import TestmonialReviews from "../components/TestimonialReviews/TestmonialReviews";
import { demoTestimonials } from "../data/restaurant-data";
import { terminalTrustedNumbers } from "../data/terminal-landing-data";

import ogImage from "../images/global_assets/og-image.png";

export default function DemoPage() {
  return (
    <Layout>
      <SEO
        title="Learn more | SpotOn Merchant Referral"
        image={`https://spoton.com/${ogImage}`}
      />
      <section className="demo mt-28 md:mt-40">
        <div className="demo__content-wrapper">
          <div className="form-wrapper">
            <MerchantReferralForm formId="76a39476-33fe-46ed-b028-a4398b5bf96e" />
          </div>
        </div>
        <Image imageName="demo_image.png" className="demo__heading-img" />
        <section style={{ marginTop: 120 }}>
          <TrustedNumnbers numbersArray={terminalTrustedNumbers} />
        </section>
        <PartnerLogos />
        <section style={{ marginTop: 60 }}>
          <TestmonialReviews sectionData={demoTestimonials} />
        </section>
      </section>
    </Layout>
  );
}
